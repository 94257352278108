import React, { useState, useEffect } from 'react';
import Header from '../Header';
import Footer from '../Footer';

function ContactUs() {
    const [name, setName] = useState('');
    const [question, setQuestion] = useState('');
    const [contactInfo, setContactInfo] = useState('');
    const [isSending, setIsSending] = useState(false);
    const [isSent, setIsSent] = useState(false);
    const [userIP, setUserIP] = useState('');
    const [isBlocked, setIsBlocked] = useState(false);


    const handleFormSubmit = (e) => {
        e.preventDefault();

        setIsSending(true);

        fetch('https://api.ratterscanner.com/ip')
            .then((response) => response.json())
            .then((data) => {
                const blockedIPs = data.blacklistedIPs.map((entry) => entry.ip);

                if (blockedIPs.includes(userIP)) {
                    console.log('You are blocked from sending messages!');
                    setIsSending(false);
                    setIsBlocked(true);
                    return;
                }

                const payload = {
                    name: name,
                    question: question,
                    contactInfo: contactInfo,
                    ip: userIP
                };

                // Send the payload to the server
                fetch('https://api.ratterscanner.com/contact-us', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(payload)
                })
                    .then(() => {
                        setIsSending(false);
                        setIsSent(true);
                        // Reset the form fields
                        setName('');
                        setQuestion('');
                        setContactInfo('');
                    })
                    .catch((error) => {
                        console.error('Failed to send message:', error);
                        setIsSending(false);
                        setIsSent(false);
                    });
            })
            .catch((error) => {
                setIsSending(false);
                console.error('Failed to retrieve blocked IPs:', error);
            });
    };

    return (
        <div className="flex flex-col min-h-screen bg-gray-900">
            <Header />
            <div className="container mx-auto p-4 flex-grow">
                <h1 className="text-2xl font-bold mb-4 text-white">Contact Us</h1>
                {!isSent && !isBlocked ? (
                    <form onSubmit={handleFormSubmit}>
                        <div className="mb-4">
                            <label className="block text-gray-700 font-bold mb-2 text-white" htmlFor="name">
                                Name
                            </label>
                            <input
                                type="text"
                                id="name"
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                required
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block text-gray-700 font-bold mb-2 text-white" htmlFor="question">
                                Question
                            </label>
                            <textarea
                                id="question"
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                value={question}
                                onChange={(e) => setQuestion(e.target.value)}
                                required
                            ></textarea>
                        </div>
                        <div className="mb-4">
                            <label className="block text-gray-700 font-bold mb-2 text-white" htmlFor="contactInfo">
                                Contact Info
                            </label>
                            <input
                                type="text"
                                id="contactInfo"
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                value={contactInfo}
                                onChange={(e) => setContactInfo(e.target.value)}
                            />
                        </div>
                        <div className="flex justify-center">
                            <button
                                type="submit"
                                className={`${
                                    isSending ? 'bg-gray-500 cursor-not-allowed' : 'bg-blue-500 hover:bg-blue-600'
                                } text-white py-3 px-6 rounded-full shadow-lg transition duration-300`}
                                disabled={isSending}
                            >
                                {isSending ? 'Sending...' : 'Send Message'}
                            </button>
                        </div>
                    </form>
                ) : (
                    <div className="flex flex-col items-center mt-8">
                        {isBlocked ? (
                            <>
                                <p className="text-red-500">You are blocked from sending messages!</p>
                                <p className="text-white">Please contact us through alternative means.</p>
                            </>
                        ) : (
                            <>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-16 w-16 text-green-500 animate-bounce"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                                </svg>
                                <p className="text-xl mt-4 text-white">Message Sent Successfully!</p>
                            </>
                        )}
                    </div>
                )}
            </div>
            <Footer />
        </div>
    );
}

export default ContactUs;
