import React from 'react';
import Header from '../Header';
import Footer from '../Footer';

function PrivacyPolicy() {
    return (
        <div className="bg-gray-900 text-white">
            <Header />
            <div className="container mx-auto p-4">
                <h1 className="text-2xl font-bold mb-4">RatterScanner.com - Privacy Policy</h1>
                <p className="text-gray-500">Last Updated: June 9, 2023</p>
                <h2 className="text-xl font-bold mt-6">Introduction</h2>
                <p>
                    RatterScanner.com (the "Website") respects your privacy and is committed to protecting your personal data.
                    This privacy policy will inform you as to how we look after your personal data when you visit our Website and
                    tell you about your privacy rights and how the law protects you.
                </p>
                <h2 className="text-xl font-bold mt-6">Personal Data We Collect</h2>
                <p>
                    The only personal data we collect is your Discord ID and profile picture hashes. Additionally, we collect
                    information from the mods you upload and scan on our Website.
                </p>
                <h2 className="text-xl font-bold mt-6">How We Use Your Personal Data</h2>
                <p>
                    We use your personal data to facilitate your use of our Website, to understand how our Website is being used
                    in order to improve our services, and to facilitate the process of scanning mods. We do not use this data for
                    marketing purposes or share it with third parties without your consent, unless required by law or as necessary
                    to protect the Website and our users.
                </p>
                <h2 className="text-xl font-bold mt-6">Data Security</h2>
                <p>
                    We have put in place appropriate security measures to prevent your personal data from being accidentally
                    lost, used, or accessed in an unauthorized way, altered, or disclosed. In addition, we limit access to your
                    personal data to those employees, agents, contractors, and other third parties who have a business need to
                    know. They will only process your personal data on our instructions and they are subject to a duty of
                    confidentiality.
                </p>
                <h2 className="text-xl font-bold mt-6">Data Retention</h2>
                <p>
                    We will only retain your personal data for as long as necessary to fulfill the purposes we collected it for,
                    including for the purposes of satisfying any legal, accounting, or reporting requirements.
                </p>
                <h2 className="text-xl font-bold mt-6">Your Legal Rights</h2>
                <p>
                    Under certain circumstances, you have rights under data protection laws in relation to your personal data.
                    These include the right to:
                </p>
                <ul className="list-disc ml-8">
                    <li>Request access to your personal data.</li>
                    <li>Request correction of your personal data.</li>
                    <li>Request erasure of your personal data.</li>
                    <li>Object to the processing of your personal data.</li>
                    <li>Request restriction of processing your personal data.</li>
                    <li>Request transfer of your personal data.</li>
                    <li>Right to withdraw consent.</li>
                </ul>
                <h2 className="text-xl font-bold mt-6">Changes to This Policy</h2>
                <p>
                    We reserve the right to update this privacy policy at any time, and we will provide you with a new privacy
                    policy when we make any substantial updates. We may also notify you in other ways from time to time about the
                    processing of your personal data.
                </p>
                <h2 className="text-xl font-bold mt-6">Contact Us </h2>
                <p>
                    If you have any questions about this privacy policy, including any requests to exercise your legal rights,
                    please contact us at:{' '}
                    <a
                        href="https://ratterscanner.com/contact-us"
                        className="text-blue-500 hover:text-blue-600 transition duration-300"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Contact us here!
                    </a>
                </p>
            </div>
            <Footer />
        </div>
    );
}

export default PrivacyPolicy;
