import React from 'react';
import Header from '../Header';
import Footer from '../Footer';

function TermsAndConditions() {
    return (
        <div className="bg-gray-900 text-white">
            <Header />
            <div className="container mx-auto p-4">
                <h1 className="text-2xl font-bold mb-4">RatterScanner.com - Terms and Conditions</h1>
                <p className="text-gray-500">Last Updated: June 9, 2023</p>
                <h2 className="text-xl font-bold mt-6">Introduction</h2>
                <p>
                    By accessing and using the RatterScanner.com website (hereinafter referred to as the "Website"), you accept
                    and agree to be bound by the terms and conditions set forth in this agreement (the "Agreement").
                </p>
                <h2 className="text-xl font-bold mt-6">Privacy</h2>
                <p>
                    Your use of the Website is also governed by our Privacy Policy, which is incorporated into this Agreement by
                    this reference.
                </p>
                <h2 className="text-xl font-bold mt-6">Intellectual Property Rights</h2>
                <p>
                    The Website, its content, features, and functionality, including but not limited to all information,
                    software, code, text, graphics, photographs, video, audio, and the design, selection, and arrangement thereof,
                    are the exclusive property of RatterScanner.com, its licensors, or other providers of such material, and are
                    protected by copyright, trademark, and other intellectual property or proprietary rights laws.
                </p>
                <h2 className="text-xl font-bold mt-6">Prohibited Conduct</h2>
                <p>
                    Unauthorized disclosure, dissemination, or use of any information, data, or content obtained from the Website
                    is strictly prohibited. This includes, but is not limited to, unauthorized use or appropriation of ideas,
                    code, or other proprietary information. Violations will not be tolerated and will result in appropriate
                    actions, including but not limited to termination of access, legal actions, and other remedies as necessary.
                </p>
                <h2 className="text-xl font-bold mt-6">Limitation of Liability</h2>
                <p>
                    In no event shall RatterScanner.com, its directors, employees, partners, agents, suppliers, or affiliates be
                    liable for any indirect, incidental, special, consequential, or punitive damages, including without
                    limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from (i) your access
                    to or use of or inability to access or use the Website; (ii) any conduct or content of any third party on the
                    Website; (iii) any content obtained from the Website; and (iv) unauthorized access, use, or alteration of
                    your transmissions or content, whether based on warranty, contract, tort (including negligence), or any other
                    legal theory, whether or not we have been informed of the possibility of such damage, and even if a remedy set
                    forth herein is found to have failed of its essential purpose.
                </p>
                <h2 className="text-xl font-bold mt-6">Indemnity</h2>
                <p>
                    You agree to defend, indemnify, and hold harmless RatterScanner.com, its officers, directors, employees, and
                    agents, from and against any claims, actions, or demands, including without limitation reasonable legal and
                    accounting fees, arising or resulting from your breach of these Terms and Conditions or your misuse of the
                    Website.
                </p>
                <h2 className="text-xl font-bold mt-6">Governing Law & Jurisdiction</h2>
                <p>
                    These Terms shall be governed and construed in accordance with the laws of the country in which
                    RatterScanner.com is based, without regard to its conflict of law provisions.
                </p>
                <h2 className="text-xl font-bold mt-6">Amendments</h2>
                <p>
                    We reserve the right, at our sole discretion, to modify or replace these Terms at any time. By continuing to
                    access or use our Website after any revisions become effective, you agree to be bound by the revised terms. If
                    you do not agree to the new terms, you are no longer authorized to use the Website.
                </p>
                <h2 className="text-xl font-bold mt-6">Contact Information</h2>
                <p>
                    If you have any questions about these Terms, please contact us at:{' '}
                    <a
                        href="https://discord.com/invite/E9uUFdjP5A"
                        className="text-blue-500 hover:text-blue-600 transition duration-300"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        https://discord.com/invite/E9uUFdjP5A
                    </a>
                </p>
            </div>
            <Footer />
        </div>
    );
}

export default TermsAndConditions;
