import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Homepage from './Pages/Homepage';
import ReportMalware from './Pages/ReportMalware';
import Privacy from './Pages/Privacy';
import Terms from './Pages/Terms';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import contactUs from "./Pages/ContactUs";




function App() {
    return (
        <Router>
            <Switch>
                <Route exact path="/" component={Homepage} />
                <Route path="/report-malware" component={ReportMalware} />
                <Route path="/privacy" component={Privacy} />
                <Route path="/terms" component={Terms} />
                <Route path="/contact-us" component={contactUs} />

            </Switch>
        </Router>
    );
}

ReactDOM.render(<App />, document.getElementById('root'));