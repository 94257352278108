import React from 'react';
import { Link } from 'react-router-dom';

function Header() {
    return (
        <header className="bg-gray-800 text-white py-4">
            <div className="container mx-auto flex items-center justify-between">
                <h1 className="text-2xl font-bold">
                    <Link to="/" className="text-white hover:text-gray-400 transition duration-300">
                        RatterScanner
                    </Link>
                </h1>
                <nav>
                    <ul className="flex space-x-4">
                        <li>
                            <Link
                                to="/report-malware"
                                className="bg-gray-600 hover:bg-gray-700 text-white py-2 px-4 rounded-full transition duration-300"
                            >
                                Report Malware
                            </Link>
                        </li>
                        <li>
                            <Link
                                to="/contact-us"
                                className="bg-gray-600 hover:bg-gray-700 text-white py-2 px-4 rounded-full transition duration-300"
                            >
                                Contact Us
                            </Link>
                        </li>
                        {/* Add more navigation links here if needed */}
                    </ul>
                </nav>
            </div>
        </header>
    );
}

export default Header;
