import React, { useState, useEffect } from 'react';
import Header from '../Header';
import Footer from '../Footer';

function ReportMalware() {
    const [malwareType, setMalwareType] = useState('');
    const [context, setContext] = useState('');
    const [evidence, setEvidence] = useState('');
    const [links, setLinks] = useState('');
    const [contactInfo, setContactInfo] = useState('');
    const [isSending, setIsSending] = useState(false);
    const [isSent, setIsSent] = useState(false);
    const [userIP, setUserIP] = useState('');
    const [isBlocked, setIsBlocked] = useState(false);

    useEffect(() => {
        // Retrieve the user's IP address
        fetch('https://api.ipify.org?format=json')
            .then((response) => response.json())
            .then((data) => {
                setUserIP(data.ip);
            })
            .catch((error) => {
                console.error('Failed to retrieve IP address:', error);
            });
    }, []);

    const handleFormSubmit = (e) => {
        e.preventDefault();

        setIsSending(true);

        fetch('https://api.ratterscanner.com/ip')
            .then((response) => response.json())
            .then((data) => {
                const blockedIPs = data.blacklistedIPs.map((entry) => entry.ip);

                if (blockedIPs.includes(userIP)) {
                    console.log('You are blocked from sending reports!');
                    setIsBlocked(true);
                    setIsSending(false);
                    return;
                }

                const payload = {
                    malwareType: malwareType,
                    context: context,
                    evidence: evidence,
                    links: links,
                    contactInfo: contactInfo,
                    ip: userIP
                };

                // Send the payload to the server
                fetch('https://api.ratterscanner.com/reports', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(payload)
                })
                    .then(() => {
                        setIsSending(false);
                        setIsSent(true);
                        // Reset the form fields
                        setMalwareType('');
                        setContext('');
                        setEvidence('');
                        setLinks('');
                        setContactInfo('');
                        console.log('Malware report sent successfully');
                    })
                    .catch((error) => {
                        setIsSending(false);
                        console.error('Failed to send malware report:', error);
                    });
            })
            .catch((error) => {
                setIsSending(false);
                console.error('Failed to retrieve blocked IPs:', error);
            });
    };

    return (
        <div className="flex flex-col min-h-screen bg-gray-900">
            <Header />
            <div className="container mx-auto p-4 flex-grow">
                <h1 className="text-2xl font-bold mb-4 text-white">Report Malware</h1>
                {isBlocked ? (
                    <p className="text-red-500 mt-4">You are blocked from sending reports!</p>
                ) : !isSent ? (
                    <form onSubmit={handleFormSubmit}>
                        <div className="mb-4">
                            <label className="block text-gray-700 font-bold mb-2 text-white" htmlFor="malwareType">
                                Malware Type
                            </label>
                            <select
                                id="malwareType"
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                value={malwareType}
                                onChange={(e) => setMalwareType(e.target.value)}
                                required
                            >
                                <option value="">Select Malware Type</option>
                                <option value="Jar">Jar</option>
                                <option value="OAuth">OAuth</option>
                                <option value="OTP">OTP</option>
                                <option value="Other/Unsure">Other/Unsure</option>
                            </select>
                        </div>
                        <div className="mb-4">
                            <label className="block text-gray-700 font-bold mb-2 text-white" htmlFor="context">
                                Context/Extra Info
                            </label>
                            <textarea
                                id="context"
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                value={context}
                                onChange={(e) => setContext(e.target.value)}
                                required
                            ></textarea>
                        </div>
                        <div className="mb-4">
                            <label className="block text-gray-700 font-bold mb-2 text-white" htmlFor="evidence">
                                Evidence
                            </label>
                            <textarea
                                id="evidence"
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                value={evidence}
                                onChange={(e) => setEvidence(e.target.value)}
                                required
                            ></textarea>
                        </div>
                        <div className="mb-4">
                            <label className="block text-gray-700 font-bold mb-2 text-white" htmlFor="links">
                                Links
                            </label>
                            <input
                                type="text"
                                id="links"
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                value={links}
                                onChange={(e) => setLinks(e.target.value)}
                                required
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block text-gray-700 font-bold mb-2 text-white" htmlFor="contactInfo">
                                Your Contact Info (Optional)
                            </label>
                            <input
                                type="text"
                                id="contactInfo"
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                value={contactInfo}
                                onChange={(e) => setContactInfo(e.target.value)}
                            />
                            <div className="mt-2">
                                <a
                                    href="https://imgur.com/a/rGzmRRP"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="text-blue-500 hover:text-blue-600 transition duration-300"
                                >
                                    How to turn on developer mode 1
                                </a>
                                <span className="text-gray-500 mx-2">|</span>
                                <a
                                    href="https://imgur.com/a/Dnakpw9"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="text-blue-500 hover:text-blue-600 transition duration-300"
                                >
                                    How to get a Discord ID 2
                                </a>
                            </div>
                        </div>
                        <div className="flex justify-center">
                            <button
                                type="submit"
                                className="bg-gray-700 hover:bg-gray-800 text-white py-3 px-6 rounded-full shadow-lg transition duration-300"
                                disabled={isSending}
                            >
                                {isSending ? 'Sending...' : 'Submit Report'}
                            </button>
                        </div>
                    </form>
                ) : (
                    <div className="flex flex-col items-center justify-center text-center">
                        <svg
                            className="h-12 w-12 text-green-500 animate-bounce mb-4"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                        </svg>
                        <p className="text-xl mt-4 text-white">Malware Report Sent Successfully!</p>
                    </div>
                )}
            </div>
            <Footer />
        </div>
    );
}

export default ReportMalware;
