import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Footer from "../Footer";

function Homepage() {
    const [alerts, setAlerts] = useState(0);

    useEffect(() => {
        axios.get('https://api.ratterscanner.com/stats')
            .then(response => {
                setAlerts(response.data.alerts);
            })
            .catch(error => {
                console.error('Failed to fetch alerts count:', error);
            });
    }, []);

    return (
        <div className="bg-gray-900 text-white padding-2">
            <header className="bg-gradient-to-r from-pink-500 via-purple-500 to-blue-500 text-white p-8">
                <div className="container mx-auto">
                    <h1 className="text-4xl font-bold mb-4 animate-fade-in-down">RatterScanner</h1>
                    <p className="text-lg">
                        Protect your Discord server against malware and malicious activities with RatterScanner, a powerful bot dedicated to keeping your community safe. Discover our features and contribute to our mission!
                    </p>
                    <div className="mt-8 flex flex-col items-center sm:flex-row sm:justify-center">
                        <a
                            href="https://discord.gg/E9uUFdjP5A"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="bg-gray-700 hover:bg-gray-800 text-white py-3 px-6 rounded-full shadow-lg transition duration-300 ease-in-out transform hover:scale-105 focus:outline-none mb-4 sm:mb-0 sm:mr-4"
                        >
                            Join our Discord
                        </a>
                        <a
                            href="https://discord.com/api/oauth2/authorize?client_id=1103624946506272818&permissions=52422&scope=bot%20applications.commands"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="bg-gray-700 hover:bg-gray-800 text-white py-3 px-6 rounded-full shadow-lg transition duration-300 ease-in-out transform hover:scale-105 focus:outline-none"
                        >
                            Invite the Bot
                        </a>
                    </div>
                </div>
            </header>

            <section className="section bg-gray-900 p-8">
                <div className="container mx-auto">
                    <h2 className="section-title text-3xl font-bold mb-8">Features</h2>

                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                        <div className="bg-gray-800 rounded-lg shadow-lg p-6">
                            <h3 className="text-xl font-bold mb-2">1. Raid Detection</h3>
                            <p className="mb-4">
                                Protect the users of your Discord server against malware and malicious activities with RatterScanner, Scanning for spambot related activities to assure your members are protected.
                            </p>
                            <p>
                                Usage: Invite the bot to your server and run the <code>/config</code> command to enable raid detection. Select a channel ID for raid alerts.
                            </p>
                        </div>

                        <div className="bg-gray-800 rounded-lg shadow-lg p-6">
                            <h3 className="text-xl font-bold mb-2">2. Jar Scanner</h3>
                            <p className="mb-4">Command: <code>/jar-scanner</code></p>
                            <p className="mb-4">
                                The jar scanner command analyzes JAR files for potential malware, this is definitely not 100% accurate.
                            </p>
                            <p>
                                Usage: Upload the JAR file to scan and wait for feedback using the <code>jar-scanner</code> command.
                            </p>
                        </div>

                    </div>
                </div>
            </section>

            <section className="section bg-gray-900 p-8">
                <div className="container mx-auto">
                    <h2 className="section-title text-3xl font-bold mb-8">Contributions</h2>
                    <div className="bg-gray-800 rounded-lg shadow-lg p-6">
                        <p className="text-lg mb-4">
                            We encourage you to contribute to our mission of combating malware and protecting Discord communities. If you come across any skyblock info-stealing malware, please provide our development team with as much information as possible, along with direct proof of its information theft capabilities. By sharing this information, you help us develop more effective countermeasures.
                        </p>

                        <div className="flex items-center justify-center">
                            <a href="/report-malware" class="bg-gray-700 hover:bg-gray-600 text-white py-3 px-6 rounded-full shadow-lg transition duration-300 mr-4 focus:outline-none hover:scale-105 text-center">Report Malware</a>
                            <a href="/contact-us" class="bg-gray-700 hover:bg-gray-600 text-white py-3 px-6 rounded-full shadow-lg transition duration-300 focus:outline-none hover:scale-105 text-center">Contact Us</a>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section bg-gray-900 p-8">
                <div className="container mx-auto">
                    <h2 className="section-title text-3xl font-bold mb-8">Stay Safe!</h2>

                    <div className="bg-gray-800 rounded-lg shadow-lg p-6">
                        <p className="text-lg mb-4">
                            Protect your community from malware. Add RatterScanner to your Discord server and keep it safe. If you have any questions or need assistance, don't hesitate to ask. Let's work together to maintain a secure community!
                        </p>

                        <p className="text-lg">Note: For any queries or concerns, feel free to ask.</p>
                    </div>

                    <div className="bg-gray-800 rounded-lg shadow-lg p-6 mt-8">
                        <h3 className="text-2xl font-bold mb-4">Banned Spambots</h3>
                        <p className="text-lg mb-4">
                            RatterScanner has encountered <span className="text-red-600 font-bold">{alerts}</span> spambots. Thanks to your reports and contributions, we actively combat spambots and protect Discord communities.
                        </p>
                    </div>
                </div>
            </section>

            <Footer />
        </div>
    );
}

export default Homepage;
