function Footer() {
    return (
        <footer className="bg-gray-900 text-white text-center py-4">
            <div className="container mx-auto">
                <div className="mb-2">
                    <a href="/terms" className="text-gray-300 hover:text-white mx-2">
                        Terms and Conditions
                    </a>
                    <span className="text-gray-500">|</span>
                    <a href="/privacy" className="text-gray-300 hover:text-white mx-2">
                        Privacy Policy
                    </a>
                </div>
                <p className="text-gray-500 text-sm">© 2023 RatterScanner. All rights reserved.</p>
            </div>
        </footer>
    );
}

export default Footer;
